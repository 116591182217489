<template>
    <div>
        <AlertaDialog :activeModal="openModal" @fechar="openModal = false"/>
        <v-card-text>
            <v-form @submit.prevent="saveAvaluation" ref="form">
                <v-container>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="macroscopicForm.eye_trauma"
                                label="Trauma Ocular"
                                value=1
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="macroscopicForm.corneal_opacity "
                                label="Opacidade de Córnea"
                                value=2
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="macroscopicForm.pterygium"
                                label="Pterígio"
                                value="3"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="macroscopicForm.bruise"
                                label="Hematoma"
                                value="4"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="macroscopicForm.edema"
                                label="Edema"
                                value="5"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="macroscopicForm.eye_disease"
                                label="Enfermidade Ocular"
                                value="6"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="macroscopicForm.senile_halo"
                                label="Halo senil"
                                value="7"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="macroscopicForm.purulent_secretion"
                                label="Infiltrado Infeccioso/ Secreção Purulenta"
                                value="8"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="macroscopicForm.bleeding"
                                label="Hemorragia"
                                value="9"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="macroscopicForm.normal_exam"
                                label="Exame normal"
                                value="10"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="macroscopicForm.others"
                                label="Outros:"
                                value="11"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4" v-if="this.macroscopicForm.others">
                            <v-textarea
                                color="green"
                                label="Descreva aqui..." 
                                rows="2"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col class="d-flex" cols="12" sm="12">
                            <v-select
                                v-model="value"
                                :items="items"
                                chips
                                label="Selecione os itens:"
                                multiple
                            ></v-select>
                        </v-col>
                    </v-row> -->
                    <div class="buttons">
                        <v-row>
                            <v-btn class="rounded-lg" elevation="0" color="green" type="submit" v-if="isCreate == true">
                                <span class="textBtn">Salvar</span>
                            </v-btn>
                            <v-btn class="rounded-lg" elevation="0" color="green" @click="update()" v-if="isCreate == false">
                                <span class="textBtn">Atualizar</span>
                            </v-btn>
                            <p class="space-personalized"></p>
                            <v-btn class="rounded-lg" elevation="0" color="grey" @click="$router.push('/captacao')">
                                <span class="textBtn">Voltar</span>
                            </v-btn>
                        </v-row>
                    </div>
                  
                </v-container>
            </v-form>

        </v-card-text>

        <v-snackbar absolute background-color="white" color="green" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar absolute background-color="white" color="red" v-model="snackbar_error">
            {{ messageError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
  
<script>
import axios from "@/axios";
import AlertaDialog from "./dialogs/AlertaDialog-comp.vue";

export default {
    data() {
        return {
            tab:1,
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            dialogDonor:false,
            donor_id: "",
            loading: true,
            options: {},
            macroscopic: [],
            macroscopicForm:{
                eye_trauma: '',
                corneal_opacity: '',
                pterygium: '',
                bruise: '',
                edema: '',
                eye_disease: '',
                senile_halo: '',
                purulent_secretion: '',
                bleeding: '',
                normal_exam: '',
                others: '',
            },
            donor:[],
            data:[],
            obj:{
                macroscopic_evaluation: []
            },
            arquivos: [],
            macroscopic_evaluation: [],
            status_serology: [],
            openButtons: false,
            selected:"",
            message: "",
            snackbar: false,
            snackbar_error: false,
            messageError: "",
            unity: "",
            donorID: "",
            donorName: "",
            is_donorName: "",
            is_donorSex: "",
            openModal: false,
            donorButton: true,
            isCreate: true,
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },

    components:{
        AlertaDialog
    },

    props: {
        donorId: Number,
    },

    created() {
        if(this.$route.query.item){
            this.isCreate = false
            const donorID = this.$route.query.item //depois mudar isso
            axios.get(`/pcr_donor_data/${donorID.id}/macroscopic`).then((response) => {
                this.macroscopic = response.data
                this.macroscopic[0].macroscopic_evaluation.forEach(item => {
                    if(item.id == 1){
                        this.macroscopicForm.eye_trauma = "1"
                    }
                    if(item.id == 2){
                        this.macroscopicForm.corneal_opacity = '2'
                    }
                    if(item.id == 3){
                        this.macroscopicForm.pterygium = '3'
                    }
                    if(item.id == 4){
                        this.macroscopicForm.bruise = '4'
                    }
                    if(item.id == 5){
                        this.macroscopicForm.edema = '5'
                    }
                    if(item.id == 6){
                        this.macroscopicForm.eye_disease = '6'
                    }
                    if(item.id == 7){
                        this.macroscopicForm.senile_halo = '7'
                    }
                    if(item.id == 8){
                        this.macroscopicForm.purulent_secretion = '8'
                    }
                    if(item.id == 9){
                        this.macroscopicForm.bleeding = '9'
                    }
                    if(item.id == 10){
                        this.macroscopicForm.normal_exam = '10'
                    }
                    if(item.id == 11){
                        this.macroscopicForm.others = '11'
                    }
                });
            });
        }
    },

    watch: {
        
    },

    methods: {
        saveAvaluation(){
            if(!this.donorId){
                this.openModal = true
            }else{
             
             this.macroscopic_evaluation = Object.values(this.macroscopicForm);
             this.macroscopic_evaluation = this.macroscopic_evaluation.filter(function (i) {
                return i
             });
            const id = this.donorId;
            this.obj.pcr_donor_data_id = this.donorId
            this.obj.macroscopic_evaluation = this.macroscopic_evaluation

                axios.post(`/pcr_donor_data/${id}/macroscopic`, this.obj).then(() => {
                    this.message = "Salvo com Sucesso."
                    this.snackbar = true;
                    setTimeout(() => {
                        this.$emit('changeTab', 2);
                    }, "1000");
                }).catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });
                
            }
        },

        update(){
            this.macroscopic_evaluation = Object.values(this.macroscopicForm);
             this.macroscopic_evaluation = this.macroscopic_evaluation.filter(function (i) {
                return i
             });
            const id = this.$route.query.item;
            this.obj.pcr_donor_data_id = id.pcr_donor_data.id
            this.obj.macroscopic_evaluation = this.macroscopic_evaluation

                axios.post(`/pcr_donor_data/${id.pcr_donor_data.id}/macroscopic`, this.obj).then(() => {
                    this.message = "Salvo com Sucesso."
                    this.snackbar = true;
                    setTimeout(() => {
                        this.$emit('changeTab', 2);
                    }, "4000");
                }).catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });
        }
    },

};
</script>
  
<style scoped>
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    /* font-weight: bold; */
}

.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #27ae60,
            #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #ee106d,
            #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 8px;

    margin-bottom: 20px;

    display: flex;
    align-items: center;
}

.icon {}

.banner>h4 {
    color: #ffffff;
    padding: 10px;
}

.cardFooter {
    padding: 0 !important;
}
</style>
  