<template>
    <div>
        <AlertaDialog :activeModal="openModal" @fechar="openModal = false"/>
        <v-card-text>
            <v-form @submit.prevent="saveFabricConditioning" ref="form">
                <v-container>
                    <v-row>
                        <!-- <v-col class="d-flex" cols="12" sm="6">
                            <v-checkbox
                                v-model="fabricConditioningForm.sterile_collector"
                                label="Coletor estéril + 5ml de SF0,9% + Tobramicina colírio"
                                value="1"
                                color="green"
                            ></v-checkbox>
                        </v-col> -->
                        <v-col class="d-flex" cols="12" sm="6">
                            <v-checkbox
                                v-model="fabricConditioningForm.termic_box"
                                label="Caixa térmica com Gelox congelado"
                                value="2"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#086318" 
                            :rules="formRules" 
                            v-model="fabricConditioningForm.temperature"
                            label="Temperatura:"
                            dense></v-text-field>
                        </v-col>
                    </v-row>
                   <div class="space-personalized"></div>
                    <div class="buttons">
                        <v-row>
                            <v-btn class="rounded-lg" elevation="0" color="green" type="submit" v-if="isCreate == true">
                                <span class="textBtn">Salvar</span>
                            </v-btn>
                            <v-btn class="rounded-lg" elevation="0" color="green" @click="update()" v-if="isCreate == false">
                                <span class="textBtn">Atualizar</span>
                            </v-btn>
                            <p class="space-personalized"></p>
                            <v-btn class="rounded-lg" elevation="0" color="grey" @click="$router.push('/captacao')">
                                <span class="textBtn">Voltar</span>
                            </v-btn>
                        </v-row>
                    </div>
                  
                </v-container>
            </v-form>
        </v-card-text>
        <v-snackbar absolute background-color="white" color="green" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar absolute background-color="white" color="red" v-model="snackbar_error">
            {{ messageError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
  
<script>
import axios from "@/axios";
import AlertaDialog from "./dialogs/AlertaDialog-comp.vue";

export default {
    data() {
        return {
            tab:3,
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            anexosForm: {
                donor_id:"",
                attachments_type_id: 1
            },
            dialogDonor:false,
            donor_id: "",
            loading: true,
            options: {},
            donor:[],
            data:[],
            arquivos: [],
            fabricConditioningForm:{
                sterile_collector:'',
                termic_box:''
            },
            fabricConditioning:{
                
            },
            obj:{
                fabric_conditioning: []
            },
            status_serology: [],
            openButtons: false,
            selected:"",
            message: "",
            snackbar: false,
            snackbar_error: false,
            messageError: "",
            unity: "",
            donorName: "",
            is_donorName: "",
            is_donorSex: "",
            openModal: false,
            donorButton: true,
            isCreate: true,
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },

    components:{
        AlertaDialog,
    },

    props:{
        donorId: Number
    },

    created() {
        if(this.$route.query.item){
            this.isCreate = false
            const donorID = this.$route.query.item //depois mudar isso
            axios.get(`/pcr_donor_data/${donorID.id}/fabric_conditioning`).then((response) => {
                this.fabricConditioning = response.data
                this.fabricConditioning[0].fabric_conditioning.forEach(item => {
                    if(item.id == 1){
                        this.fabricConditioningForm.sterile_collector = "1"
                    }
                    if(item.id == 2){
                        this.fabricConditioningForm.termic_box = '2'
                    }
                });
            });
        }
    },


    methods: {
        saveFabricConditioning(){
            if(!this.donorId){
                this.openModal = true
            }else{
            this.fabric_conditioning = Object.values(this.fabricConditioningForm);
            this.fabric_conditioning = this.fabric_conditioning.filter(function (i) {
                return i
             });
            const id = this.donorId;
            this.obj.fabric_conditioning = this.fabric_conditioning
                axios.post(`/pcr_donor_data/${id}/fabricConditioning`, this.obj).then(() => {
                    this.message = "Salvo com Sucesso."
                    this.snackbar = true;
                    setTimeout(() => {
                        this.$emit('changeTab', 4);
                    }, "1000");
                }).catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });  
            }
        },

        update(){
            this.fabric_conditioning = Object.values(this.fabricConditioningForm);
            this.fabric_conditioning = this.fabric_conditioning.filter(function (i) {
                return i
             });
            const id = this.$route.query.item;
            this.obj.fabric_conditioning = this.fabric_conditioning
                axios.post(`/pcr_donor_data/${id.pcr_donor_data.id}/fabricConditioning`, this.obj).then(() => {
                    this.message = "Salvo com Sucesso."
                    this.snackbar = true;
                }).catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                }); 
        }
    },

};
</script>
  
<style scoped>

.space-personalized{
    padding: 15px;
    margin-bottom: 20px;
}
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    /* font-weight: bold; */
}

.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #27ae60,
            #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #ee106d,
            #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 8px;

    margin-bottom: 20px;

    display: flex;
    align-items: center;
}

.icon {}

.banner>h4 {
    color: #ffffff;
    padding: 10px;
}

.cardFooter {
    padding: 0 !important;
}
</style>
  