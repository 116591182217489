<template>
    <div>
        <v-card-text>
            <v-form @submit.prevent="saveDonor" ref="form">
                <v-container>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-text-field 
                            color="#086318" 
                            :rules="formRules" 
                            label="Nome" 
                            placeholder="Nome" 
                            outlined 
                            v-model="donorForm.name"
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-text-field 
                            color="#086318" 
                            label="Nome da Mãe" 
                            placeholder="Nome da mãe" 
                            :rules="formRules"
                            v-model="donorForm.mother_name"
                            outlined 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-text-field 
                            color="#086318" 
                            label="CPF" 
                            placeholder="CPF" 
                            maxlength="14" 
                            v-mask="'###.###.###-##'"
                            :rules="formRules"
                            v-model="donorForm.cpf"
                            outlined 
                            dense></v-text-field>
                        </v-col>
                </v-row>
                <v-row>
                        <v-col class="d-flex" cols="12" sm="2">
                            <v-select 
                            :items="sex"
                            :rules="formRules"
                            color="#086318" 
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            v-model="donorForm.sex_id"
                            label="Gênero">
                            </v-select>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#086318" 
                            label="Data de Nascimento"
                            :rules="formRules"
                            type="date" 
                            v-model="donorForm.birth_date"
                            outlined 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#086318" 
                            label="Idade" 
                            :rules="formRules"
                            v-model="donorForm.age"
                            placeholder="anos" 
                            outlined 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-text-field 
                            color="#086318" 
                            label="História Clínica" 
                            :rules="formRules"
                            v-model="donorForm.clinical_historic"
                            placeholder="História Clínica" 
                            outlined 
                            dense></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="2">
                            <v-text-field 
                            color="#086318"  
                            :rules="formRules"
                            v-model="donorForm.pcr_date"
                            label="PCR"
                            type="date" 
                            outlined 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="1">
                            <v-text-field 
                            color="#086318" 
                            label="Hora"  
                            maxlength="5" 
                            :rules="formRules"
                            v-mask="'##:##'"
                            v-model="donorForm.pcr_hour"
                            outlined 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="2">
                            <v-select 
                            :items="yesOrNot"
                            :rules="formRules"
                            color="#086318" 
                            item-value="value"
                            item-text="name"  
                            dense 
                            outlined 
                            v-model="donorForm.cold_body"
                            label="Corpo Resfriado">
                            </v-select>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="2">
                            <v-text-field 
                            color="#086318" 
                            :rules="formRules"
                            label="Data do encerramento do PME (Ultimo exame):"
                            v-model="donorForm.ending_pme_date"
                            type="date" 
                            outlined 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="1">
                            <v-text-field 
                            color="#086318" 
                            :rules="formRules"
                            label="Hora"
                            maxlength="5" 
                            v-mask="'##:##'"  
                            v-model="donorForm.ending_pme_hour"
                            outlined 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-text-field 
                            color="#086318" 
                            label="Causa do Óbito"
                            :rules="formRules"
                            v-model="donorForm.cause_of_death"
                            outlined 
                            dense></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col class="d-flex" cols="12" sm="2">  A área solicitou a remoção deste campo
                            <v-text-field 
                            color="#086318" 
                            :rules="formRules"
                            label="Clampeamento da aorta (ME):"
                            v-model="donorForm.aorta_clamping_date"
                            type="date" 
                            outlined 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="1">
                            <v-text-field 
                            color="#086318" 
                            :rules="formRules"
                            v-model="donorForm.aorta_clamping_hour"
                            label="Hora"
                            maxlength="5" 
                            v-mask="'##:##'"  
                            outlined 
                            dense></v-text-field>
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="2">
                            <v-text-field 
                            color="#086318" 
                            :rules="formRules" 
                            label="Data da Captação:"
                            v-model="donorForm.captation_date"
                            type="date" 
                            outlined 
                            dense></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#086318" 
                            label="Horário da captação OD:"
                            v-model="donorForm.captation_hour_right_eye"
                            maxlength="5" 
                            v-mask="'##:##'"
                            outlined 
                            dense></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#086318" 
                            label="Horário da captação OE:"
                            v-model="donorForm.captation_hour_left_eye"
                            maxlength="5" 
                            v-mask="'##:##'"
                            outlined 
                            dense></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-text-field 
                            color="#086318" 
                            :rules="formRules" 
                            v-model="donorForm.pickup"
                            label="Captador:"
                            outlined 
                            dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-radio-group v-model="donorForm.corneal_laterality">
                                <p>Lateralidade da Córnea</p>

                                <v-radio label="Esquerdo" 
                                value=1 
                                color="#086318"
                                ></v-radio>

                                <v-radio label="Direito" 
                                value=2
                                color="#086318"
                                ></v-radio>

                                <v-radio label="Ambos" 
                                value=3
                                color="#086318"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <div class="space-personalized"></div>
                    <div class="buttons">
                        <v-row>
                            <v-btn class="rounded-lg" elevation="0" color="green" type="submit" v-if="isCreate == true">
                                <span class="textBtn">Salvar</span>
                            </v-btn>
                            <v-btn class="rounded-lg" elevation="0" color="green" @click="updateDonor()" v-if="isCreate == false">
                                <span class="textBtn">Atualizar</span>
                            </v-btn>
                            <p class="space-personalized"></p>
                            <v-btn class="rounded-lg" elevation="0" color="grey" @click="$router.push('/captacao')">
                                <span class="textBtn">Voltar</span>
                            </v-btn>
                        </v-row>
                    </div>
                  
                </v-container>
            </v-form>
        </v-card-text>
        <v-snackbar absolute background-color="white" color="green" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar absolute background-color="white" color="red" v-model="snackbar_error">
            {{ messageError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
  
<script>
import axios from "@/axios";
import FormatDate from "@/utils/FormatDate"

export default {
    data() {
        return {
            tab:3,
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            donorForm: {

            },
            dialogDonor:false,
            loading: true,
            options: {},
            donor:[],
            data:[],
            arquivos: [],
            status_serology: [],
            openButtons: false,
            selected:"",
            message: "",
            snackbar: false,
            snackbar_error: false,
            messageError: "",
            openModal: false,
            donorButton: true,
            donorID: "",
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ],
            sex:[],
            isCreate: true,
            yesOrNot: [
                {
                    name: 'Sim',
                    value: '1'
                },
                {
                    name: 'Não',
                    value: '2'
                }
            ],
        };
    },

    props:{
        resource: Object
    },

    created() {
        if(this.resource.pcr_donor_data){
            this.isCreate = false
            this.donorForm = this.resource.pcr_donor_data
            this.donorForm.name = this.resource.name
            this.donorForm.mother_name = this.resource.mother_name
            this.donorForm.sex_id = parseInt(this.resource.sex_id)
            this.donorForm.birth_date = FormatDate.dataAtualFormatada(this.resource.birth_date)
            let ano = this.donorForm.birth_date.split("-", 1) //ajeitar isso depóis
            let date = new Date();
            let currentYear = date.getFullYear();
            let age = currentYear - ano
            this.donorForm.age = age
            this.donorForm.aorta_clamping_date = FormatDate.dataAtualFormatada(this.donorForm.aorta_clamping_date)
            this.donorForm.ending_pme_date = FormatDate.dataAtualFormatada(this.donorForm.ending_pme_date)
            this.donorForm.pcr_date = FormatDate.dataAtualFormatada(this.donorForm.pcr_date)
        }
        axios.get("/sex").then((response) => {this.sex = response.data.data;});
    },

    computed: {
        birth_date(){
            return this.donorForm.birth_date;
        },
    },

    watch: {
        options: {
            handler() {
                this.indoDataTable();
            },
        },

        birth_date(e){
            let ano = e.split("-", 1)
            let date = new Date();
            let currentYear = date.getFullYear();
            let age = currentYear - ano
            this.donorForm.age = age
        },

        deep: true,
    },

    methods: {
        saveDonor(){
         if(this.$refs.form.validate() == true) {
            const donor = new Object
                donor.kind_of_donor_id = 5
                donor.name = this.donorForm.name
                donor.mother_name = this.donorForm.mother_name
                donor.cpf = this.donorForm.cpf
                donor.sex_id = this.donorForm.sex_id
                donor.birth_date = this.donorForm.birth_date
                donor.clinical_historic = this.donorForm.clinical_historic
                donor.hospital_id = this.$store.state.unity_id
                donor.unity_id = this.$store.state.unity_id
                donor.donor_status_id = 2
            const pcrDonor = new Object
                pcrDonor.cause_of_death = this.donorForm.cause_of_death
                pcrDonor.pcr_date = this.donorForm.pcr_date
                pcrDonor.pcr_hour = this.donorForm.pcr_hour
                pcrDonor.cold_body = this.donorForm.cold_body
                pcrDonor.ending_pme_date = this.donorForm.ending_pme_date
                pcrDonor.ending_pme_hour = this.donorForm.ending_pme_hour
                pcrDonor.aorta_clamping_date = this.donorForm.aorta_clamping_date
                pcrDonor.aorta_clamping_hour = this.donorForm.aorta_clamping_hour
                pcrDonor.corneal_laterality = this.donorForm.corneal_laterality
         
                console.log("Doador", pcrDonor)
            axios.post(`/donor/salvarDoador`, donor).then((response) => {
                this.donorID = response.data.id.id;
                pcrDonor.donor_id = this.donorID

                axios.post(`/pcr_donor_data`, pcrDonor).then((response) => {
                    this.donorID = response.data.id.id
                    this.message = "Doador registrado com sucesso."
                    this.snackbar = true;
                    setTimeout(() => {
                        this.$emit('changeTab', 1);
                        this.$emit('donorId', this.donorID);
                    }, "1000");
                }).catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });
            }).catch((err) => {
                err;
                var error = "";
                Object.values(this.$store.state.errorMessage).map((value) => {
                    error = value;
                });
                this.displayError.message = error.toString();
                this.displayError.display = true;
                setTimeout(() => {
                    this.displayError.display = false;
                }, 3000);
            });
        }
      },

      updateDonor(){
        if(this.$refs.form.validate() == true) {
            const donor = new Object
                donor.name = this.donorForm.name
                donor.mother_name = this.donorForm.mother_name
                donor.sex_id = this.donorForm.sex_id
                donor.birth_date = this.donorForm.birth_date
                donor.hospital_id = this.$store.state.unity_id
                donor.unity_id = this.$store.state.unity_id
                donor.donor_status_id = 2
            const pcrDonor = new Object
                pcrDonor.cause_of_death = this.donorForm.cause_of_death
                pcrDonor.pcr_date = this.donorForm.pcr_date
                pcrDonor.pcr_hour = this.donorForm.pcr_hour
                pcrDonor.cold_body = this.donorForm.cold_body
                pcrDonor.ending_pme_date = this.donorForm.ending_pme_date
                pcrDonor.ending_pme_hour = this.donorForm.ending_pme_hour
                pcrDonor.aorta_clamping_date = this.donorForm.aorta_clamping_date
                pcrDonor.aorta_clamping_hour = this.donorForm.aorta_clamping_hour
            axios.patch(`/donor/${this.resource.id}`, donor).then(() => {
                axios.patch(`/pcr_donor_data/${this.resource.pcr_donor_data.id}`, pcrDonor).then((response) => {
                    this.message = response.data.message
                    this.snackbar = true;
                });
            });
        }
      }
    },
};
</script>
  
<style scoped>

.space-personalized{
    padding: 15px;
    margin-bottom: 30px;
}
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    /* font-weight: bold; */
}

.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #27ae60,
            #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #ee106d,
            #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 8px;

    margin-bottom: 20px;

    display: flex;
    align-items: center;
}

.icon {}

.banner>h4 {
    color: #ffffff;
    padding: 10px;
}

.cardFooter {
    padding: 0 !important;
}
</style>
  