<template>
    <div>
        <AlertaDialog :activeModal="openModal" @fechar="openModal = false"/>
        <v-card-text>
            <v-form @submit.prevent="saveBloodSampleCollection" ref="form">
                <v-container>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-checkbox
                                v-model="bloodSampleCollectionForm.pephriferal"
                                label="Periférica"
                                value="1"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-checkbox
                                v-model="bloodSampleCollectionForm.subclavian"
                                label="Subclávia"
                                value="2"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-checkbox
                                v-model="bloodSampleCollectionForm.femoral"
                                label="Femoral"
                                value="3"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-checkbox
                                v-model="bloodSampleCollectionForm.intracardiac"
                                label="Intracardíaca"
                                value="4"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-checkbox
                                v-model="bloodSampleCollectionForm.others"
                                label="Outro"
                                value="5"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3" v-if="this.bloodSampleCollectionForm.others">
                            <v-textarea
                                v-model="bloodSampleCollectionForm.description"
                                color="green"
                                label="Especifique aqui..." 
                                rows="1"
                                dense
                            ></v-textarea>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3" v-if="this.bloodSampleCollectionForm.others">
                            <v-textarea
                                color="green"
                                label="Calibre da agulha:" 
                                rows="1"
                                dense
                            ></v-textarea>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3" v-if="this.bloodSampleCollectionForm.others">
                            <v-textarea
                                color="green"
                                label="Calibre do Scalp:" 
                                rows="1"
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-checkbox
                                v-model="bloodSampleCollectionForm.sample_centrifugation"
                                label="Centrifugação da Amostra"
                                value="6"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="5">
                            <v-text-field 
                            color="#086318" 
                            :rules="formRules" 
                            v-model="pcrDonorDataForm.hemoba_delivery_time"
                            label="Horário de Entrega da Coleta do Hemoba:"
                            dense></v-text-field>
                        </v-col>
                    </v-row>
                   
                    <div class="space-personalized"></div>
                    <div class="buttons">
                        <v-row>
                            <v-btn class="rounded-lg" elevation="0" color="green" type="submit" v-if="isCreate == true">
                                <span class="textBtn">Concluir</span>
                            </v-btn>
                            <v-btn class="rounded-lg" elevation="0" color="green" @click="update()" v-if="isCreate == false">
                                <span class="textBtn">Atualizar</span>
                            </v-btn>
                            <p class="space-personalized"></p>
                            <v-btn class="rounded-lg" elevation="0" color="red" @click="distribution()">
                                <span class="textBtn">Distribuição/Disponibilização</span> 
                            </v-btn>
                            <!-- <p class="space-personalized"></p>
                            <v-btn class="rounded-lg" elevation="0" color="grey" @click="$router.push('/doador')">
                                <span class="textBtn">Voltar</span>
                            </v-btn> -->
                        </v-row>
                    </div>
                  
                </v-container>
            </v-form>
        </v-card-text>
        <v-snackbar absolute background-color="white" color="green" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar absolute background-color="white" color="red" v-model="snackbar_error">
            {{ messageError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
  
<script>
import axios from "@/axios";
import AlertaDialog from "./dialogs/AlertaDialog-comp.vue";

export default {
    data() {
        return {
            tab:4,
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            pcrDonorDataForm:{},
            bloodSampleCollectionForm:{
                pephriferal:'',
                subclavian:'',
                femoral:'',
                intracardiac:'',
                others:'',
                sample_centrifugation:'',
            },
            obj:{
                blood_sample_collection: []
            },
            dialogDonor:false,
            donor_id: "",
            loading: true,
            options: {},
            donor:[],
            data:[],
            arquivos: [],
            status_serology: [],
            openButtons: false,
            selected:"",
            message: "",
            snackbar: false,
            snackbar_error: false,
            messageError: "",
            unity: "",
            donorName: "",
            is_donorName: "",
            is_donorSex: "",
            openModal: false,
            donorButton: true,
            isCreate: true,
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },

    components:{
        AlertaDialog
    },

    props:{
        donorId: Number
    },

    created() {
        if(this.$route.query.item){
            this.isCreate = false
            const donorID = this.$route.query.item//depois mudar isso
            axios.get(`/pcr_donor_data/${donorID.id}/blood_sample_collection`).then((response) => {
                this.bloodSampleCollection = response.data
                this.bloodSampleCollection[0].blood_sample_collection.forEach(item => {
                    if(item.id == 1){
                        this.bloodSampleCollectionForm.pephriferal = "1"
                    }
                    if(item.id == 2){
                        this.bloodSampleCollectionForm.subclavian = '2'
                    }
                    if(item.id == 3){
                        this.bloodSampleCollectionForm.femoral = '3'
                    }
                    if(item.id == 4){
                        this.bloodSampleCollectionForm.intracardiac = '4'
                    }
                    if(item.id == 5){
                        this.bloodSampleCollectionForm.others = '5'
                    }
                    if(item.id == 6){
                        this.bloodSampleCollectionForm.sample_centrifugation = '6'
                    }
                });
            });
        }
    },

    methods: {

        distribution(){
            this.$router.push({path: '/disponibilizacao-doador', query:{item:this.$route.query.item}})
        },

        saveBloodSampleCollection(){
            if(!this.donorId){
                this.openModal = true
            }else{
            this.blood_sample_collection = Object.values(this.bloodSampleCollectionForm);
            this.blood_sample_collection = this.blood_sample_collection.filter(function (i) {
                return i
             });
            const id = this.donorId;
            const pcr_donor = this.pcrDonorDataForm
            this.obj.blood_sample_collection = this.blood_sample_collection
                axios.post(`/pcr_donor_data/${id}/bloodCollection`, this.obj).then(() => {
                    axios.put(`/pcr_donor_data/${id}`, pcr_donor).then(() => {
                        this.message = "Doador PCR cadastrado com sucesso!"
                        this.snackbar = true;
                        setTimeout(() => 
                            this.$router.push({ path: '/captacao'}), 
                        "1000");
                    })  
                }).catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });  
            }
        },

        update(){
            this.blood_sample_collection = Object.values(this.bloodSampleCollectionForm);
            this.blood_sample_collection = this.blood_sample_collection.filter(function (i) {
                return i
             });
            const id = this.$route.query.item;
            const pcr_donor = this.pcrDonorDataForm
            this.obj.blood_sample_collection = this.blood_sample_collection
                axios.post(`/pcr_donor_data/${id.pcr_donor_data.id}/bloodCollection`, this.obj).then(() => {
                    axios.put(`/pcr_donor_data/${id}`, pcr_donor).then(() => {
                        this.message = "Atualizado com sucesso!"
                        this.snackbar = true;
                    })  
                }).catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });  
        }
    },

};
</script>
  
<style scoped>
.space-personalized{
    padding: 15px;
    margin-bottom: 20px;
}
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    /* font-weight: bold; */
}

.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #27ae60,
            #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #ee106d,
            #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 8px;

    margin-bottom: 20px;

    display: flex;
    align-items: center;
}

.icon {}

.banner>h4 {
    color: #ffffff;
    padding: 10px;
}

.cardFooter {
    padding: 0 !important;
}
</style>
  