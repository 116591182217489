<template>
    <div>
        <v-card class="rounded-lg" elevation="0">
            <v-tabs v-model="tab" fixed-tabs background-color="green" dark>
                <v-tabs-slider color="#086318"></v-tabs-slider>
                    <v-tab>Doador PCR</v-tab>
                    <v-tab>Avaliação Macroscópica</v-tab>
                    <v-tab>Descrição Cirúrgica</v-tab>
                    <v-tab>Acondicionamento do Tecido</v-tab>
                    <v-tab>Coleta da Amostra Sanguinea</v-tab>

                    <v-tab-item>
                        <Doador @changeTab="tab = $event" @donorId="donorId = $event" :resource="resource"/>
                    </v-tab-item>

                    <v-tab-item>
                        <AvaliacaoMacroscopica @changeTab="tab = $event" :donorId="donorId"/>
                    </v-tab-item>

                    <v-tab-item>
                        <DescricaoCirurgica @changeTab="tab = $event" :donorId="donorId"/>
                    </v-tab-item>

                    <v-tab-item>
                        <AcondicionamentoDoTecido @changeTab="tab = $event" :donorId="donorId"/>
                    </v-tab-item>

                    <v-tab-item>
                        <ColetaAmostraSanguinea :donorId="donorId"/>
                    </v-tab-item>
            </v-tabs>
        </v-card>
    </div>
</template>

<script>
//import axios from "@/axios";
//import EventBus from '@/utils/eventBus'
import ColetaAmostraSanguinea from './ColetaAmostraSanguinea-comp.vue';
import AcondicionamentoDoTecido from './AcondicionamentoDoTecido-comp.vue';
import DescricaoCirurgica from './DescricaoCirurgica-comp.vue';
import AvaliacaoMacroscopica from './AvalicaoMacroscopica-comp.vue'
import Doador from './Doador-comp.vue'
export default {
    data() {
        return {
            enable: false,
            openOrgonForm: false,
            displayError: {
                display: false,
                message: [],
            },
            donorId: "",
            disable: false,
            enableTableandSolutions: false,
            disableNefrectomy: false,
            dialogSolution: false,
            disableEye: false,
            isKidneyEnable: false,
            SaveContinue: true,
            continueButton: false,
            solutionsDialog: false,
            confirmDialog: false,
            tab: null,
            teste: false,
            isKidney: false,
            isCornea: false,
            loading: true,
            dataTable: [],
            solutionTable: [],
            options: {},
            message: "",
            snackbar: false,
            messageError: "",
            orgons: [],
            laterality: [],
            solucoes: [],
            organCaptured: {
                donor_id: " "
            },
            professional: {
                captation_id: "",
            },
            preservation: {
                captation_id: "",
                donor_id: "",
                orgon_id: ""
            },
            preservationsolution:"",
            nephrectomyForm: {
                captation_id:""
            },
            captationsForm: {
                donor_id: ""
            },
            save: false,
            arrayOrgon: [],
            resource: {},
            ativeSolutions: false,
            ativeTeam: false,
            disabledEye: false,
            ativeTeambuttom: false,
            disableNephrectomyForm: false,
            dialogNewRegister: false,
            dialogErrorSolution: false,
            captationId:"",
            yesorNot: [
                {
                    name: 'Sim',
                    value: '1'
                },
                {
                    name: 'Não',
                    value: '2'
                }
            ],
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ]
        };
    },

    components:{
        ColetaAmostraSanguinea,
        AcondicionamentoDoTecido,
        DescricaoCirurgica,
        AvaliacaoMacroscopica,
        Doador
    },

    created() {
     
        this.resource = {...this.$route.query.item}
    },

    computed: {
        
    },
    
    watch: {
        
    },
        
    methods: {
        
    }
}
</script>

<style scoped>
.dialogSolution>h4{
color: #413f3f;
padding: 20px;
}
.deathDialog>h4{
color: #413f3f;
padding: 20px;
}
.text-h5{
background-color: #17995c;
color: white;
text-shadow: 1px 2px 2px hsl(160, 50%, 38%)
}
.text-error-h5{
background-color: rgb(236, 84, 78);
color: white;
text-shadow: 1px 2px 2px hsl(1, 61%, 26%)
}
.donorIcon{
padding:  8px 8px 8px 0;
}

.buttons{
padding:7px
}
.newDialog>h4{
color: #413f3f;
padding: 20px;
}
.text-h5{
background-color: #50c78f;
color: white;
text-shadow: 1px 2px 2px #309171
}
.header-table {
display: flex;
margin-bottom: 20px;
}
.space-personalized {
padding: 4px;
}
.d-flex {
padding: 0 12px;
}

.v-btn:not(.v-btn--round).v-size--default {
height: 40px !important;
}

.textBtn {
font-family: "Poppins";
color: #ffffff;
}

.textBtn{
color:#ffffff;
}
.banner>h3 {
color: #ffffff;
padding: 18px 0 18px 0;
}

.cardFooter {
padding: 0 !important;
}
</style>