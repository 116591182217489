<template>
    <div>
     <AlertaDialog :activeModal="openModal" @fechar="openModal = false"/>
        <v-card-text>
            <v-form @submit.prevent="saveDecription" ref="form">
                <v-container>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="surgicalDescriptionForm.asepsis_Antisepsis"
                                label=" Assepsia e Antissepsia com PVPI e campos cirúrgicos estéreis"
                                value="1"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="surgicalDescriptionForm.rightEyeballEnucleation"
                                label="Enucleação do Globo Ocular Direito"
                                value="2"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="surgicalDescriptionForm.leftEyeballEnucleation"
                                label="Enucleação do Globo Ocular Esquerdo"
                                value="3"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="surgicalDescriptionForm.orbital_Cavity_Reconstruction"
                                label="Reconstrução da cavidade orbitária"
                                value="4"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                        <!-- <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="surgicalDescriptionForm.elideSuture"
                                label="Sutura de pálpebras"
                                value="5"
                                color="green"
                            ></v-checkbox>
                        </v-col> -->
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-checkbox
                                v-model="surgicalDescriptionForm.complications"
                                label="Intercorrência durante o procedimento:"
                                value="6"
                                color="green"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="3" v-if="this.surgicalDescriptionForm.complications">
                            <v-text-field 
                            color="#086318" 
                            v-model="surgicalDescriptionForm.description"
                            :rules="formRules" 
                            label="Qual?"
                            dense></v-text-field>
                        </v-col>
                    </v-row>
                    <p class="space-personalized"></p>
                    <div class="buttons">
                        <v-row>
                            <v-btn class="rounded-lg" elevation="0" color="green" type="submit" v-if="isCreate == true">
                                <span class="textBtn">Salvar</span>
                            </v-btn>
                            <v-btn class="rounded-lg" elevation="0" color="green" @click="update()" v-if="isCreate == false">
                                <span class="textBtn">Atualizar</span>
                            </v-btn>
                            <p class="space-personalized"></p>
                            <v-btn class="rounded-lg" elevation="0" color="grey" @click="$router.push('/captacao')">
                                <span class="textBtn">Voltar</span>
                            </v-btn>
                        </v-row>
                    </div>
                  
                </v-container>
            </v-form>
        </v-card-text>
        
        <v-snackbar absolute background-color="white" color="green" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar absolute background-color="white" color="red" v-model="snackbar_error">
            {{ messageError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
  
<script>
import axios from "@/axios";
import AlertaDialog from "./dialogs/AlertaDialog-comp.vue";

export default {
    data() {
        return {
            tab:2,
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            anexosForm: {
                donor_id:"",
                attachments_type_id: 1
            },
            headers: [
            {
                text: "Nome do exame",
                align: "center",
                value: "attachment_name",
            },
            { 
                text: "Ações", 
                value: "actions", 
                align: "center",
                sortable: false 
            },
            ],
            dialogDonor:false,
            donor_id: "",
            loading: true,
            options: {},
            surgeryDescription: {},
            donor:[],
            data:[],
            surgicalDescriptionForm: {
                asepsis_Antisepsis: "", 
                rightEyeballEnucleation: "",
                leftEyeballEnucleation:"",
                orbital_Cavity_Reconstruction:"",
                elideSuture:"",
                complications:""
            },
            obj:{
                surgical_description: []
            },
            arquivos: [],
            status_serology: [],
            openButtons: false,
            selected:"",
            message: "",
            snackbar: false,
            snackbar_error: false,
            messageError: "",
            unity: "",
            donorName: "",
            is_donorName: "",
            is_donorSex: "",
            openModal: false,
            donorButton: true,
            isCreate: true,
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },

    components:{
        AlertaDialog
    },

    props:{
        activeModal: Boolean,
        donorId: Number    
    },
    
    created() {
        if(this.$route.query.item){
        this.isCreate = false
        const donorID = this.$route.query.item //depois mudar isso
        axios.get(`/pcr_donor_data/${donorID.id}/surgical_description`).then((response) => {
            this.surgeryDescription = response.data
            this.surgeryDescription[0].surgical_description.forEach(item => {
                if(item.id == 1){
                    this.surgicalDescriptionForm.asepsis_Antisepsis = "1"
                }
                if(item.id == 2){
                    this.surgicalDescriptionForm.rightEyeballEnucleation = '2'
                }
                if(item.id == 3){
                    this.surgicalDescriptionForm.leftEyeballEnucleation = '3'
                }
                if(item.id == 4){
                    this.surgicalDescriptionForm.orbital_Cavity_Reconstruction = '4'
                }
                if(item.id == 5){
                    this.surgicalDescriptionForm.elideSuture = '5'
                }
                if(item.id == 6){
                    this.surgicalDescriptionForm.complications = '6'
                }
            });
        });
      }
    },

    methods: {
       
        saveDecription() {
            if(!this.donorId){
                this.openModal = true
            }else{
            this.surgical_description = Object.values(this.surgicalDescriptionForm);
            this.surgical_description = this.surgical_description.filter(x => x.trim().length > 0);
            const id = this.donorId;
            this.obj.surgical_description = this.surgical_description
                axios.post(`/pcr_donor_data/${id}/surgicalDescription`, this.obj).then(() => {
                    this.message = "Salvo com Sucesso."
                    this.snackbar = true;
                    setTimeout(() => {
                        this.$emit('changeTab', 3);
                    }, "1000");
                }).catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });  
            }
        },

        update(){
            this.surgical_description = Object.values(this.surgicalDescriptionForm);
            this.surgical_description = this.surgical_description.filter(function (i) {
                return i
            });
            const id = this.$route.query.item;
            this.obj.surgical_description = this.surgical_description
                axios.post(`/pcr_donor_data/${id.pcr_donor_data.id}/surgicalDescription`, this.obj).then(() => {
                    this.message = "Salvo com Sucesso."
                    this.snackbar = true;
                }).catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });  
        }

    }
};
</script>
  
<style scoped>
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    /* font-weight: bold; */
}

.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #27ae60,
            #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #ee106d,
            #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 8px;

    margin-bottom: 20px;

    display: flex;
    align-items: center;
}

.icon {}

.banner>h4 {
    color: #ffffff;
    padding: 10px;
}

.cardFooter {
    padding: 0 !important;
}
</style>
  