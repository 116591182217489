<template>
    <v-row justify="center">
      <v-dialog
        v-model="activeModal"
        persistent
        width="450"
      >
        <v-card>
          <v-card-title>
            <v-icon style="padding: 5px; color: rgb(237, 123, 16);">mdi-alert</v-icon>
            <span class="text-h5">Não foi possível salvar!</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-card-text class="text-subtitle-1">
                    Para avançar é preciso concluir o cadastro de informações básicas do doador. Volte 
                    para a primeira aba (Doador PCR), preencha os dados solicitados e continue.
                </v-card-text>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn
                style="background-color:rgb(237, 123, 16); color: white;"
                variant="text"
                @click="closeDialog()"
                center
                >
                Ok
                </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

        <v-snackbar background-color="white" color="#57b567" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar background-color="white" color="#d21631" v-model="snackbarError">
            {{ messageError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbarError = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </v-row>
  </template>

  <script>
  import axios from "@/axios";
  export default {
    data: () => ({
      dialog: false,
      refused: "",
      snackbar: false,
      message:"",
      formRules: [
          (v) => !!v || "Este campo é obrigatório",
      ],
      messageError:"",
      snackbarError:false,
      dialogError:""
    }),

    props:{
        activeModal: Boolean,
        donorId: Object
    },

    methods:{
    
        closeDialog(){
            this.$emit('fechar');
        },

        sendRefusal(){
          if(this.refused) {
            const data =  new Object();
            data.refused = this.refused
            data.authorized = 2
            
            axios
                .patch(`/donor/${this.donorId.donor_id}`, data)
                .then(() => {
                this.message = "Recusa submetida!";
                this.snackbar = true;
                this.dialog = false;
                this.$router.push({ name: "Doadores" });
            })
                .catch((err) => {
                err;
                var error = "";
                Object.values(this.$store.state.errorMessage).map((value) => {
                    error = value;
                });
                this.displayError.message = error.toString();
                this.displayError.display = true;
                setTimeout(() => {
                    this.displayError.display = false;
                }, 3000);
            });
          }else{
            this.messageError = "Verifique se o campo está preenchido corretamente.";
            this.snackbarError = true;
            this.dialogError = false;
          }
        }
    }
  }
</script>

<style>

small{
    font-size: 18px;
}
</style>

